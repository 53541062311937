class Endpoints{
    static register = "/user/register";
    static login = "/user/login";
    static home = "/";
    static error = "*";
    static resetpassword = "/user/resetpassword";
    static changepassword = "/user/changepassword";
    static artisteadvice = "/blog/artisteadvice";
    static sellmusiconline = "/market/sellmusiconline";
    static dashboard = "/user/dashboard";
    static contact = "/page/contact-us";
    static about = "/page/about-us";
    static profile = "/user/profile";

    // admin section enpoints
    static admin = "/admin";
    static adminhome = "home";
    static adminblog = "blog"
    static adminblogpost = "post";
    static adminblogdelete = "delete";
    static adminblogupdate = "update";
    static adminblogreceive = "recieve";
    static adminuser = "user";
    static adminuserlogin = "login";
    static adminuserblocked = "blocked";
    // API ENDPOINTS
    static getallblogs = "https://picsum.photos/v2/list?page=1&limit=100";
}
export default Endpoints;