import React from 'react';

function BlogUpdate(props) {
    return (
        <div>
            <h1>Update blog</h1>
        </div>
    );
}

export default BlogUpdate;