import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Comments from '../../components/Comments';
import BlogViewDetails from '../../components/BlogViewDetails';
import Loading from '../../components/Loading';
import FooterSection from '../../components/FooterSection';
import TestimonialSection from '../../components/TestimonialSection';
import HttpVerbs from '../../static/js/HttpVerbs';
import Endpoints from '../../logics/Endpoints';

const BlogView = () => {
    let { postId, postTitle} = useParams();
    const [getPosts, setPosts] = useState([]);

    useEffect(() => {
        const singlePost = async () => {
            const gatDatalink = await HttpVerbs.fetchBlogs(Endpoints.getallblogs, postId);
            setPosts(gatDatalink);
        }
        singlePost();
    }, [postId,postTitle]);

    if (getPosts.length < 1) {
        return (<Loading />);
    } else {
        return (
            <div className='blog-view-wrapper'>
                <BlogViewDetails data={getPosts} />
                <Comments commentId={postId} />
                <div className='artist-advice-wrap'>
                    <TestimonialSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default BlogView;