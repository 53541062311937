import React from "react";
import boom from "./../static/images/boomplay.webp";
import itune from "./../static/images/itunes.webp";
import youtube from "./../static/images/youtube.webp";
import tiktok from "./../static/images/tik-b.webp";
import vevo from "./../static/images/vevo.webp";
import amazon from "./../static/images/amazon-music-b.webp";
import sportify from "./../static/images/spotify-b.webp";
import instagram from "./../static/images/instagram.webp";
import CustomAnimation from "../static/js/CustomAnimation";

const SocialMediaSection = () => {
  return (
    <section className="social-section-image">
      <div className="social-icons-to-content container">
        <h1>
          <CustomAnimation>
            Be Discovered and Reach More Fans Globally
          </CustomAnimation>
        </h1>
        <p>
          <CustomAnimation>
            We make music distribution very simple and easy, enabling you to
            maximize revenue, visibility, and traction. Release your music with
            FlareTechMusic today; Get discovered, grow your fan base, and keep
            100% of your copyrights and earnings.
          </CustomAnimation>
        </p>
      </div>
      <div className="social-images container">
        <div className="image-items">
          <CustomAnimation>
            <img src={boom} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={itune} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={youtube} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={tiktok} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={vevo} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={amazon} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={sportify} alt="" />
          </CustomAnimation>
        </div>
        <div className="image-items">
          <CustomAnimation>
            <img src={instagram} alt="" />
          </CustomAnimation>
        </div>
      </div>
    </section>
  );
};

export default SocialMediaSection;
