
class HttpVerbs {
    // get all data from server
    static fetchBlogs = async (url, postId = '') => {
        try {
            // Making the fetch call
            const response = await fetch(url);
            // Checking if the response is okay

            const dataResp = await response.json();

            // if the post id is set then return the data with that post id
            if (postId !== '') {
                // find the data with the id and return it
                const respData = dataResp.find(img => img.id.toString() === postId.toString());
                const singleDataResp = {
                    id: respData.id,
                    title: respData.author,
                    author: respData.author,
                    date: new Date().toISOString(),
                    content: `Photograph taken by ${respData.author}`,
                    image: `https://picsum.photos/id/${respData.id}/1600/900`,
                };
                return singleDataResp;
            }

            // return all the data
            const allData = dataResp.map(data => ({
                id: data.id,
                title: data.author,
                author: data.author,
                date: new Date().toISOString(),
                content: `Photograph taken by ${data.author}`,
                image: `https://picsum.photos/id/${data.id}/1600/900`,
            }));
            // Returning the parsed data
            return allData;


        } catch (error) {
            console.error('There was a problem with the fetch operation:', error.message);
        }
    }


}
export default HttpVerbs;