/** @format */

import React, { useState } from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import CustomFormInput from "../../../components/CustomFormInput";
import CustomButton from "../../../components/CustomButton";
import CustomLink from "../../../components/CustomLink";
import CustomSpacer from "../../../components/CustomSpacer";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  /**set the states */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/user/dashboard", {
      relative: true,
     });
  };

  return (
    <div>
      <br />
      <form className="form-wrapper" onSubmit={handleSubmit}>
        <CustomFormInput
          placeholder="Email Address"
          className="input-field"
          type="email"
          id="email"
          value={email}
          PrefIcon={FaEnvelope}
          onChange={handleEmailChange}
        />

        <CustomFormInput
          type="password"
          id="password"
          className="input-field"
          placeholder="Enter Password"
          value={password}
          onChange={handlePasswordChange}
          PrefIcon={FaLock}
        />

        <CustomLink
          linkLabel="Forgot Password ?"
          className="forgot-password"
          route="/user/resetpassword"
        />

        <CustomSpacer size={200}/>

        <CustomButton
          type="submit"
          btnText="Sign In"
          className="submit-button"
        />

        <div className="sign_reg_in_linkWrap">
          <CustomLink
            textLabel="Don’t have an account?"
            linkLabel="Sign up"
            className="sign_reg_in_link"
            route="/user/register"
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
