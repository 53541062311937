import React, { useState, useEffect } from 'react';
import CustomAnimation from '../static/js/CustomAnimation';
import FuncModules from '../static/js/FuncModules';

const Comments = (props) => {
    const [comments, setComments] = useState([]);
    const [currentComment, setCurrentComment] = useState('');
    const [currentReply, setCurrentReply] = useState(''); // To capture the current reply being typed
    const [showComments, setShowComments] = useState(true);

    useEffect(() => {
        const savedComments = localStorage.getItem(`comments-${props.commentId}`);
        if (savedComments) {
            setComments(JSON.parse(savedComments));
        }
    }, [props.commentId]);

    const handleCommentChange = (event) => {
        setCurrentComment(event.target.value);
    };

    const handleReplyChange = (event) => {
        setCurrentReply(event.target.value);
    };

    const handleCommentSubmit = () => {
        if (currentComment.trim() !== '') {
            const newComment = {
                id: Date.now(), // Added a unique id for each comment
                text: currentComment,
                senderName: "User Name",
                avatar: "https://via.placeholder.com/40",
                date: new Date().toISOString(),
                replies: []
            };
            const updatedComments = [...comments, newComment];
            setComments(updatedComments);
            setCurrentComment('');

            localStorage.setItem(`comments-${props.commentId}`, JSON.stringify(updatedComments));
        }
    };

    const handleReplySubmit = (commentId) => {
        if (currentReply.trim() !== '') {
            const updatedComments = comments.map(comment => {
                if (comment.id === commentId) {
                    return {
                        ...comment,
                        replies: [...comment.replies, currentReply]
                    };
                }
                return comment;
            });
            setComments(updatedComments);
            setCurrentReply('');

            localStorage.setItem(`comments-${props.commentId}`, JSON.stringify(updatedComments));
        }
    };

    return (
        <div className='comments-wrapper'>
            <div className='container'>
                <div className="comment-input">
                    <textarea
                        value={currentComment}
                        onChange={handleCommentChange}
                        placeholder="Write a comment..."
                    />
                    <button onClick={handleCommentSubmit}>Comment</button>
                </div>
                <div className="comments-header" onClick={() => setShowComments(!showComments)}>
                    Comments
                </div>
                {showComments &&
                    <div className="comments-list">
                        {comments.map((comment) => (
                            <CustomAnimation key={comment.id}>
                                <div className="comment">
                                    <img src={comment.avatar} alt="avatar" className="avatar" />
                                    <div className="comment-content">
                                        <span className="sender-name">{comment.senderName}</span>
                                        <span className="comment-text">{comment.text}</span>
                                        <div className="comment-info">
                                            <span className="comment-date">{FuncModules.formatDate(new Date(comment.date).toLocaleString())}</span>
                                            <div className='like-share-wrap'>
                                            <button className="like-btn">Like</button>
                                            <button className="share-btn">Share</button>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </CustomAnimation>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default Comments;