/** @format */

import React from "react";

const CustomFormInput = ({
  value,
  id,
  className,
  type,
  placeholder,
  PrefIcon,
  onChange,
}) => {
  return (
    <div className="input-container">
      <label htmlFor={id}>
        <PrefIcon className="input-icon" />
      </label>
      <input
        type={type}
        id={id}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomFormInput;
