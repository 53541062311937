import React from 'react';

function BlogDelete(props) {
    return (
        <div>
            <h1>Delete blog</h1>
        </div>
    );
}

export default BlogDelete;