import React from "react";
import SliderSection from "../../../components/SliderSection";
import SocialMediaSection from "../../../components/SocialMediaSection";
import StartSellingSection from "../../../components/StartSellingSection";
import WhyChooseUs from "../../../components/WhyChooseUs";
import SocialContactSection from "../../../components/SocialContactSection";
import ControlOfYourMusic from "../../../components/ControlOfYourMusic";
import TestimonialSection from "../../../components/TestimonialSection";
import FooterSection from "../../../components/FooterSection";
const Home = () => {
  return (
    <div className="main-wrapper">
      {/* slider section */}
      <SliderSection />
      <main className="content-main-wrapper">
        {/* social media section */}
        <SocialMediaSection />
        {/* Start Selling Section */}
        <StartSellingSection />
        {/* why choose us */}
        <WhyChooseUs />
        {/* social contacts */}
        <SocialContactSection />
        {/* take control of your music business section */}
        <ControlOfYourMusic />
        {/* testimonial section */}
        <TestimonialSection />
        {/* footer section */}
        <FooterSection />
      </main>
    </div>
  );
};

export default Home;
