import React from 'react';
import loading from "./../static/images/loading2.gif";
function Loading(props) {
    return (
        <div className='loading-indicator-wrap'>
            <div className='container'>
                <div className='loading-indicator'>
                    <img src={loading} alt="Loading..." />
                </div>
            </div>
        </div>
    );
}
export default Loading;