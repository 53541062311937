import React from "react";
import FlareLogo from "./../static/logos/logo.webp";

const PreloaderAnimatedIcon = () => {
  return (
    <section>
      <div className="pageloading" id="pageloading">
        <div className="top_loading" id="top_loading"></div>
        <div className="icon_loading_gif" id="icon_loading_gif">
          <div className="icon_loading_spin"><img src={FlareLogo} alt="Loading.." /></div>
        </div>
      </div>
    </section>
  );
};

export default PreloaderAnimatedIcon;
