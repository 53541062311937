import React from "react";
import SocialContactImage from "./../static/images/social-img.webp";

const SocialContactSection = () => {
  return (
    <section className="social-contact-wrapper">
      <div className="social-contact-container container">
        <div className="social-contact-image-wrapper">
          <img src={SocialContactImage} alt="" />
        </div>
        <div className="social-contact-right-content">
          <div className="social-contact-text-wrapper">
            <h1>Organic Playlist</h1>
            <h2>For independent artistes and record labels</h2>
            <p>
              Distributing your songs isn’t just enough; you need marketing and
              promotion to maximize revenue from sales. Secure placements today
              on massive playlists and watch your stream counts grow over time.
              With editorial playlists, FlareTechMusic sends your tracks to
              infinity and beyond. We’ll work hand-in-hand with you to ensure
              you’re on playlists that guarantee organic results.
            </p>
          </div>
          <div className="social-contact-btn-wrapper">
            <a href="https://chat.whatsapp.com/K9H6woL8nOR5s9Czbvv7a4">
            Contact us on WhatsApp
            </a>
            <a href="https://t.me/+hrC8wL3m-foxOGRk">
              Contact us on Telegram
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialContactSection;
