import React from "react";

const ControlOfYourMusic = () => {
  return (
    <section className="control-of-your-music-wrapper">
      <div className="control-of-your-music-overlay">
        <div className="control-of-your-music-container container">
          <div className="control-of-your-music-text-wrapper">
            <h1>Take Complete Control of your music business.</h1>
            <p>There is never a perfect timing but now, Start now!!!</p>
          </div>
          <a href="https://distribution.flaretechmusic.com/login.cfm">
            Sign up now
          </a>
        </div>
      </div>
    </section>
  );
};
export default ControlOfYourMusic;
