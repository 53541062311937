/** @format */
import { useNavigate} from "react-router-dom";
import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import CustomFormInput from "../../../components/CustomFormInput";
import CustomButton from "../../../components/CustomButton";
import CustomSpacer from "../../../components/CustomSpacer";

const ResetForm = () => {
  const navigate = useNavigate();
  /**set the states */
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/user/changepassword", {
      relative: true,
     });
    //console.log(email);
  };

  return (
    <div>
      <br />
      <form className="form-wrapper" onSubmit={handleSubmit}>
        <CustomFormInput
          placeholder="Enter Recovery Email"
          id="resetEmail"
          className="input-field"
          type="email"
          value={email}
          PrefIcon={FaEnvelope}
          onChange={handleEmailChange}
        />
        <CustomSpacer size={50}/>
        <CustomButton
          btnText="Continue"
          className="submit-button"
          type="submit"
        />
        <br />
      </form>
    </div>
  );
};

export default ResetForm;
