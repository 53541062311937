export class FuncModules {
  static menuToggle = () => {
    let btnIcon = document.getElementById("btnIcon");
    let mainHeader = document.getElementById("main_header");
    if (mainHeader.style.display === "block") {
      mainHeader.style.display = "none";
      btnIcon.innerHTML = "&#9776;";
      btnIcon.style.padding = "5px 12px";
    } else {
      mainHeader.style.display = "block";
      btnIcon.innerHTML = "&#9747;";
      btnIcon.style.padding = "5px 10px";
    }
    mainHeader.addEventListener("click", (e) => {
      btnIcon.innerHTML = "&#9776;";
      btnIcon.style.padding = "5px 12px";
      mainHeader.style.display = "none";
    });

  };

  static formatDate = (isoDateString) =>{
    
    const date = new Date(isoDateString);

    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][date.getUTCMonth()];
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const second = date.getUTCSeconds();

    // Function to get ordinal suffix
    function getOrdinal(n) {
      var s = ["th", "st", "nd", "rd"];
      var v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    return `${month} ${getOrdinal(day)} ${year} | ${hour}:${minute}:${second}`;
  }
}
export default FuncModules;

