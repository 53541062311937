/** @format */

import React, { useState } from "react";
import ArtisteForm from "./ArtisteForm";
import RadioStationForm from "./RadioStationForm";
import { LazyMotion, domAnimation, stagger, m } from "framer-motion";
import CustomImage from "../../../components/CustomImage";
import Logo from "./../../../static/logos/splash.webp";
import { RegexMethod } from "../../../services/RegexMethods";

const Register = () => {
  const [activeTab, setActiveTab] = useState("artiste");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log(RegexMethod.emailRegex("kenneth@gmail.com"));
  };

  return (
    <div>
      <LazyMotion features={domAnimation}>
        <m.div
          className="form-container"
          animate={{ opacity: [0.5, 1], delay: stagger(0.1) }}
        >
          <div className="logo-wrap-center">
            <CustomImage imageSrc={Logo} alt="Logo image" width={300} />
          </div>
          <div className="form-wrap">
            <div className="form-content">
              <div className="label-wrap">
                <h2>Sign Up</h2>
                {/* Your signup form JSX */}
                <p>Let’s get you started</p>
              </div>
              <div className="form-u-are">
                <p>Are you a/an</p>
              </div>
              <div className="button-toggle-wrap">
                <m.button
                  whileTap={{ x: -20 }}
                  className="artiste-login"
                  onClick={() => handleTabClick("artiste")}
                  style={{
                    backgroundColor:
                      activeTab === "artiste" ? "#570761" : "transparent",
                    color: activeTab === "artiste" ? "#FFFFFF" : "#570761",
                  }}
                >
                  Artiste
                </m.button>
                <m.button
                  className="radio-login"
                  whileTap={{ x: 20 }}
                  onClick={() => handleTabClick("signup")}
                  style={{
                    backgroundColor:
                      activeTab === "signup" ? "#570761" : "transparent",
                    color: activeTab === "signup" ? "#FFFFFF" : "#570761",
                  }}
                >
                  Signup
                </m.button>
              </div>
              <br />

              {activeTab === "artiste" ? (
                <m.div animate={{ y1: 20 }}>
                  <ArtisteForm />
                </m.div>
              ) : (
                <m.div animate={{ y: 20 }}>
                  <RadioStationForm />
                </m.div>
              )}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    </div>
  );
};

export default Register;
