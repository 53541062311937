import React from "react";
import CustomAnimation from "../static/js/CustomAnimation";

const WhyChooseUs = () => {
  return (
    <section className="choose-us-section">
      <div className="choose-us-container container">
        <div className="choose-us-header">
          <h3>Why Choose</h3>
          <h1>FlareTechMusic</h1>
        </div>
        <div className="choose-us-items-wrap">
          <CustomAnimation duration={0.2}>
            <div className="choose-us-items">
              <span>&#127757;</span>
              <h1>Global Music Distribution</h1>
              <p>
                Get your music playing in over 150 digital stores and streaming
                services across 100+ countries worldwide.
              </p>
            </div>
          </CustomAnimation>
          <CustomAnimation duration={0.2}>
            <div className="choose-us-items">
              <span>&#128188;</span>
              <h1>Keep 100% of Your Sales</h1>
              <p>
                  We never take a cut of your sales. Every penny you earn is put
                  right into your FlaretechMusic account for your cashout.
              </p>
            </div>
          </CustomAnimation>
          <CustomAnimation>
            <div className="choose-us-items">
              <span>&#127897;</span>
              <h1>Comprehensive Sales Data</h1>
              <p>
                Find out exactly where fans are downloading and streaming your
                music.
              </p>
            </div>
          </CustomAnimation>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
