import React from "react";
import startSellingImage from "./../static/images/music-career.webp";
import CustomAnimation from "../static/js/CustomAnimation";

const StartSellingSection = () => {
  return (
    <section className="start-selling-section">
      <div className="start-selling-wrap container">
        <div className="start-selling-section-image">
          <CustomAnimation>
            <img src={startSellingImage} alt="" />
          </CustomAnimation>
        </div>
        <div className="start-selling-section-text">
          <CustomAnimation>
            <h1>Be Your Own Label</h1>
          </CustomAnimation>
          <p>
            <CustomAnimation>
              Choose from any of our subscription plans to release, distribute
              and promote your music directly to audiences around the globe. Let
              FlareTechMusic see to the rest, from music file distribution to
              generation of your actionable analytics, monitoring, and tracking
              of your product performance and payments.
            </CustomAnimation>
          </p>
          <CustomAnimation>
            <a href="https://distribution.flaretechmusic.com/login.cfm">
              Start Selling
            </a>
          </CustomAnimation>
        </div>
      </div>
    </section>
  );
};

export default StartSellingSection;
