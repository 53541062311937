import React from 'react';

import PropTypes from 'prop-types';

const CustomSpacer = ({horizontal, size}) => {
  const defaultValue = 'auto';

  return (
    <div
      style={{
        width: horizontal ? size : defaultValue,
        height: !horizontal ? size : defaultValue,
      }}
    />
  );
};

CustomSpacer.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  horizontal: PropTypes.bool,
};

CustomSpacer.defaultProps = {
  horizontal: false,
};

export default CustomSpacer;