import React from 'react';
import FooterSection from '../../components/FooterSection';

const Error404 = () => {
    return (
        <div className='error-page-404-wrapper'>
            <div className='container'>
                <div className='error-404-content-wrap'>
                    <div className="error-404-content">
                    <h1>404</h1>
                        <p>Sorry we could not find the page</p>
                    </div>
                </div>
            </div>
            <FooterSection/>
        </div>
    );
};

export default Error404;