import React from 'react';
import BlogPost from '../blog/BlogPost';

const AdminHome = () => {
    return (
        <div className='admin-home-wrapper'>
            <div className='container'>
                <BlogPost/>
            </div>
        </div>
    );
};

export default AdminHome;