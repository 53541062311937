import React from 'react';
import ArtisteConnectJumbo from '../../../components/ArtisteConnectJumbo';
import SocialContactSection from '../../../components/SocialContactSection';
import TestimonialSection from '../../../components/TestimonialSection';
import FooterSection from '../../../components/FooterSection';

const Contact = () => {
    return (
        <div className="main-wrapper">
            <ArtisteConnectJumbo jumboText="Contact Us" />
            <div className='artist-advice-wrap'>
                {/* social contacts */}
                <SocialContactSection />
                <TestimonialSection />
                <FooterSection />
            </div>
        </div>
    );
};

export default Contact;