
import React from 'react'
import FooterSection from '../../components/FooterSection';
import TestimonialSection from '../../components/TestimonialSection';
import WhyChooseUs from '../../components/WhyChooseUs';
import PriceTable from '../../components/PriceTable';
import ArtisteConnectJumbo from '../../components/ArtisteConnectJumbo';
import PriceTabeTwo from '../../components/PriceTabeTwo';

const SellMusic = () => {
  return (
    <div className="main-wrapper">
      <ArtisteConnectJumbo jumboText="Sell music online"/>
      <div className='sell-music-online-wrapper'>
        <PriceTable />
        <PriceTabeTwo/>
      </div>
      <main className="content-main-wrapper">
        <WhyChooseUs />
        <TestimonialSection />
        <FooterSection />
      </main>
    </div>
  )
}

export default SellMusic;