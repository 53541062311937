/** @format */

import React from "react";

const CustomImage = ({ imageSrc, alt, width, height, radius, fit }) => {
  return (
    <div className="parentDiv">
      <div className="logoContainer">
        <img
          src={imageSrc}
          alt={alt}
          width={width}
          height={height}
          style={{ borderRadius: { radius }, objectFit: { fit } }}
          className="logoImage"
        />
      </div>
    </div>
  );
};

export default CustomImage;
