
import React from 'react';
import CustomAnimation from '../static/js/CustomAnimation';

const PriceTable = () => {
    return (
        <div className='pricing-table-container'>
            <section className='container pricing-table-wrapper'>
                <h1>Pricing Table</h1>
                <div className='price-list-wrapper'>
                    <CustomAnimation duration={0.2}>
                        <div className='pricing-items'>
                            <h1>FREE SUB</h1>
                            <div className='pricing-details-wrap'>
                                <div className='item-number item-pricing-wrap-bg1'>
                                    <h1>1</h1>
                                </div>
                                <div className='item-pricing-wrap item-pricing-wrap-bg1'>
                                    <h1 className='price'>$0.00</h1>
                                    <div className='pricing-feature-wrapper'>
                                        <li>keep 50% Royalty</li>
                                        <li>1 Artiste</li>
                                        <li>1 Track</li>
                                        <li>1 Product</li>
                                        <li>Free for one year</li>
                                        <li>150 Outlets</li>
                                    </div>
                                </div>
                                <a className='item-pricing-wrap-bg1' href="">SUBSCRIBE</a>
                            </div>
                        </div>
                    </CustomAnimation>
                    <CustomAnimation duration={0.3}>
                        <div className='pricing-items'>
                            <h1>BASIC</h1>
                            <div className='pricing-details-wrap'>
                                <div className='item-number item-pricing-wrap-bg2'>
                                    <h1>2</h1>
                                </div>
                                <div className='item-pricing-wrap item-pricing-wrap-bg2'>
                                    <h1 className='price'>$9.99</h1>
                                    <div className='pricing-feature-wrapper'>
                                        <li>keep 100% Royalty</li>
                                        <li>1 Artiste</li>
                                        <li>1 Track</li>
                                        <li>1 Product</li>
                                        <li>1 year</li>
                                        <li>150 Outlets</li>
                                    </div>
                                </div>
                                <a className='item-pricing-wrap-bg2' href="">SUBSCRIBE</a>
                            </div>
                        </div>
                    </CustomAnimation>
                    <CustomAnimation duration={0.4}>
                        <div className='pricing-items'>
                            <h1>STANDARD</h1>
                            <div className='pricing-details-wrap'>
                                <div className='item-number item-pricing-wrap-bg3'>
                                    <h1>3</h1>
                                </div>
                                <div className='item-pricing-wrap item-pricing-wrap-bg3'>
                                    <h1 className='price'>$19.99</h1>
                                    <div className='pricing-feature-wrapper'>
                                        <li>keep 100% Royalty</li>
                                        <li>1 Artiste</li>
                                        <li>10 Tracks</li>
                                        <li>1 Product</li>
                                        <li>1 year</li>
                                        <li>150 Outlets</li>
                                    </div>
                                </div>
                                <a className='item-pricing-wrap-bg3' href="">SUBSCRIBE</a>
                            </div>
                        </div>
                    </CustomAnimation>
                    <CustomAnimation duration={0.5}>
                        <div className='pricing-items'>
                            <h1>FOREVER BASIC</h1>
                            <div className='pricing-details-wrap'>
                                <div className='item-number item-pricing-wrap-bg4'>
                                    <h1>4</h1>
                                </div>
                                <div className='item-pricing-wrap item-pricing-wrap-bg4'>
                                    <h1 className='price'>$23.99</h1>
                                    <div className='pricing-feature-wrapper'>
                                        <li>keep 80% Royalty</li>
                                        <li>1 Artiste</li>
                                        <li>1 Track</li>
                                        <li>1 Product</li>
                                        <li>Forever</li>
                                        <li>150 Outlets</li>
                                    </div>
                                </div>
                                <a className='item-pricing-wrap-bg4' href="">SUBSCRIBE</a>
                            </div>
                        </div>
                    </CustomAnimation>
                </div>
            </section>
        </div>
    );
}

export default PriceTable;