import React from 'react';
import CustomAnimation from '../static/js/CustomAnimation';
import FuncModules from '../static/js/FuncModules';

function BlogViewDetails(props) {
    return (
        <div className='blog-details-view-component'>
            <section className='container'>
                <div className='blog-view-content'>
                    <CustomAnimation>
                        <div className='blog-title-and-date-wrap'>
                            <h1>{props.data.title}</h1>
                            <div className='author-and-date-wrap'>
                                <span>Author: {props.data.author}</span>
                                <span style={{textAlign: "right"}}>{FuncModules.formatDate(props.data.date)}</span>
                            </div>
                        </div>
                    </CustomAnimation>
                    <CustomAnimation>
                        <img className='blog-detail-view-image' src={props.data.image} alt="" />
                    </CustomAnimation>
                    <CustomAnimation>
                        <div className='blog-detail-view-content'>{props.data.content}</div>
                    </CustomAnimation>
                </div>
            </section>
        </div>
    );
}

export default BlogViewDetails;