
window.onscroll = () => {
  menuHeaderStick();
};

//=================This is to stick Basket to a position===============//

function menuHeaderStick() {
  let header = document.getElementById("main_header");
  let sticky = header.offsetTop;
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}


