import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom"
import Endpoints from "./logics/Endpoints";

import Login from "./screens/auth/login/Login";
import Register from "./screens/auth/register/Register";
import Home from "./screens/webpage/home/Home";
import About from "./screens/webpage/about/About";
import Contact from "./screens/webpage/contact/Contact";
import ResetPass from "./screens/auth/reset_password/ResetPass";
import ChangePassword from "./screens/auth/change_password/ChangePassword";
import SellMusic from "./screens/multimedia/SellMusic";
import Navigation from "./screens/Navigation";
import BlogView from "./screens/multimedia/BlogView";
import Blog from "./screens/multimedia/Blog";
import Error404 from "./screens/errors/Error404";
import AdminHome from "./screens/admin/home/AdminHome";
import AdminLogin from "./screens/admin/user/AdminLogin";
import BlogPost from "./screens/admin/blog/BlogPost";
import BlogDelete from "./screens/admin/blog/BlogDelete";
import BlogRetreive from "./screens/admin/blog/BlogRetreive";
import BlogUpdate from "./screens/admin/blog/BlogUpdate";
import AdminBlockUser from "./screens/admin/user/AdminBlockUser";



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={Endpoints.home} element={<Navigation />}>
      <Route index element={<Home />} />
      <Route path={Endpoints.about} element={<About />} />
      <Route path={Endpoints.login} element={<Login />} />
      <Route path={Endpoints.register} element={<Register />} />
      <Route path={Endpoints.resetpassword} element={<ResetPass />} />
      <Route path={Endpoints.changepassword} element={<ChangePassword />} />
      <Route path={Endpoints.contact} element={<Contact />} />
      <Route path={Endpoints.sellmusiconline} element={<SellMusic />} />
      <Route path={Endpoints.artisteadvice} element={<Blog />} />
      <Route path={Endpoints.artisteadvice + "/:postId?/:postTitle?"} element={<BlogView />} />
      {/* admin section endpoints */}
      <Route path={Endpoints.admin}>
        <Route index path={Endpoints.adminhome} element={<AdminHome />} />
        {/* admin blogs */}
        <Route path={Endpoints.adminblog}>
          <Route path={Endpoints.adminblogpost} element={<BlogPost />} />
          <Route path={Endpoints.adminblogdelete} element={<BlogDelete />} />
          <Route path={Endpoints.adminblogreceive} element={<BlogRetreive />} />
          <Route path={Endpoints.adminblogupdate} element={<BlogUpdate />} />
        </Route>
        {/* admin user */}
        <Route path={Endpoints.adminuser}>
          <Route path={Endpoints.adminuserlogin} element={<AdminLogin />} />
          <Route path={Endpoints.adminuserblocked} element={<AdminBlockUser />} />
        </Route>
      </Route>
      <Route path={Endpoints.error} element={<Error404 />} />
    </Route>
  )
);
function App() {
  return <RouterProvider router={router} />;
}

export default App;