/** @format */
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import CustomFormInput from "../../../components/CustomFormInput";
import CustomButton from "../../../components/CustomButton";

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  /**set the states */
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/user/dashboard", {
     relative: true,
    });
  };

  return (
    <div>
      <br />
      <form className="form-wrapper" onSubmit={handleSubmit}>
        <CustomFormInput
          placeholder="New Password"
          id="newPassword"
          className="input-field"
          type="password"
          value={newPassword}
          PrefIcon={FaLock}
          onChange={handleNewPasswordChange}
        />

        <CustomFormInput
          placeholder="Confirm New Password"
          id="confirmNewPassword"
          className="input-field"
          type="password"
          value={confirmNewPassword}
          PrefIcon={FaLock}
          onChange={handleConfirmNewPasswordChange}
        />

        <CustomButton
          type="submit"
          className="submit-button"
          btnText="Confirm"
        />
        <br />
      </form>
    </div>
  );
};

export default ChangePasswordForm;
