import React from "react";
import CustomAnimation from "../static/js/CustomAnimation";

const TestimonialSection = () => {
  return (
    <section className="testimonial-wrapper">
      <div className="testimonial-container container">
        <div className="testimonial-header">
          <h1>
            <CustomAnimation>Testimonials</CustomAnimation>
          </h1>
        </div>
        <div className="testimonial-content-slide">
          <div className="testimonial-slider">
            <div className="testimonial-slider-items">
              <p>
                <CustomAnimation>
                  The actional analytics is top-notch, showing a detailed
                  breakdown of my sales revenue. I can cash out 100% of my
                  earnings.
                </CustomAnimation>
              </p>
              <strong>Matthew George</strong>
            </div>
            <div className="testimonial-slider-items">
              <p>
                <CustomAnimation>
                  This platform is easy to use. The control it gives you is what
                  I love the most. I no longer need to call anyone for my
                  revenue reports.
                </CustomAnimation>
              </p>
              <strong>Loveth Jones</strong>
            </div>
            <div className="testimonial-slider-items">
              <p>
                <CustomAnimation>
                  I have come across a couple of music distribution platforms,
                  and I am rating the FlareTechMusic a four Star because there
                  is room for more improvement.
                </CustomAnimation>
              </p>
              <strong>Gabriel James</strong>
            </div>
            <div className="testimonial-slider-items">
              <p>
                <CustomAnimation>
                  I have come across a couple of music distribution platforms,
                  and I am rating the FlareTechMusic a four Star because there
                  is room for more improvement.
                </CustomAnimation>
              </p>
              <strong>Paul Uche</strong>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
