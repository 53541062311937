/** @format */

import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Logo from "./../static/logos/FlareTechMusicLogo.webp";
import FuncModules from "../static/js/FuncModules";
import CustomAnimation from "../static/js/CustomAnimation";
import Endpoints from "../logics/Endpoints";
import PreloaderAnimatedIcon from "../components/PreloaderAnimatedIcon";
import AppInstallUi from "../components/AppInstallUi";

const Navigation = () => {
  const [activeNav, setActiveNav] = useState("home");

  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveNav(tab);
  };

  return (
    <>

      <div className="mobile-toggle-icon" id="mobile_toggle_icon">
        <CustomAnimation>
          <span id="btnIcon" onClick={() => FuncModules.menuToggle()}>
            &#9776;
          </span>
        </CustomAnimation>
      </div>
      <nav className="nav-container" id="main_header">
        <CustomAnimation>
          <div className="nav-content-wrapper container">
            <div className="logo-wrapper">
              <a href={Endpoints.home}>
                <img className="logo-name" src={Logo} alt="" />
              </a>
            </div>
            <div className="nav-wrapper">
              <ul className="nav-menu-ul">
                <li>
                  <Link
                    id={
                      activeNav === "home" || activeNav === ""
                        ? "active"
                        : "inactive"
                    }
                    to={Endpoints.home}
                    onClick={() => handleTabClick("home")}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    id={activeNav === "sell_music" ? "active" : "inactive"}
                    to={Endpoints.sellmusiconline}
                    onClick={() => handleTabClick("sell_music")}
                  >
                    Sell Your Music Online
                  </Link>
                </li>
                <li>
                  <Link
                    id={activeNav === "artiste" ? "active" : "inactive"}
                    to={Endpoints.artisteadvice}
                    onClick={() => handleTabClick("artiste")}
                  >
                    Artiste Advice
                  </Link>
                </li>
                <li>
                  <Link
                    id={activeNav === "contact" ? "active" : "inactive"}
                    to={Endpoints.contact}
                    onClick={() => handleTabClick("contact")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sign-reg-btn-wrapper">
              <ul className="auth-btns">
                <li>
                  <a href="https://distribution.flaretechmusic.com/login.cfm">
                    Login
                  </a>
                </li>
                <li>
                  <a href="https://distribution.flaretechmusic.com/signup.cfm">
                    Sell Your Music Online
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </CustomAnimation>
      </nav>
      <main>
        <div id="pageloading" style={{ display: isPageLoading ? 'flex' : 'none' }}>
          {/* loading indicator */}
          <PreloaderAnimatedIcon />
        </div>
        <div id="top_loading" style={{ display: isPageLoading ? 'block' : 'none' }}>
          {/* loading indicator */}
          <PreloaderAnimatedIcon />
        </div>
        {/* this outlet is where the rest of the page content will be displayed why the nav remain at the top accross all pages */}
        <Outlet />
      </main>
    </>
  );
};

export default Navigation;
