import React from 'react';

function BlogRetreive(props) {
    return (
        <div>
            <h1>Retrieve blog</h1>
        </div>
    );
}

export default BlogRetreive;