/** @format */

import React from "react";
import { LazyMotion, domAnimation, stagger, m } from "framer-motion";
import CustomImage from "../../../components/CustomImage";
import Logo from "./../../../static/logos/splash.webp";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword = () => {
  return (
    <div>
      <LazyMotion features={domAnimation}>
        <m.div
          className="form-container"
          animate={{ opacity: [0.5, 1], delay: stagger(0.1) }}
        >
          <div className="logo-wrap-center">
            <CustomImage imageSrc={Logo} alt="Logo image" width={300} />
          </div>
          <div className="form-wrap">
            <div className="form-content">
              <div className="label-wrap">
                <h2>Account Recovery</h2>
                {/* Your signup form JSX */}
                <p>Let’s get you back onboard </p>
              </div>
              <ChangePasswordForm />
            </div>
          </div>
        </m.div>
      </LazyMotion>
    </div>
  );
};

export default ChangePassword;
