/** @format */

export class RegexMethod {
  static emailRegex(email: string)  {
    var match = email.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
    return match;
  }

  static passwordRegex(password: String) {
    return password;
  }

  static usernameRegex(username: String) {
    return username;
  }

  static phoneNumberRegex(phoneNumber: string): string {
    return phoneNumber;
  }

  static removeSpaceRegex(space: String): String {
    return space.replace(/\s+/gi, "");
  }
}
