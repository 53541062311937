import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Endpoints from "../logics/Endpoints";
import Loading from "./Loading";
import HttpVerbs from "../static/js/HttpVerbs";
import CustomAnimation from "../static/js/CustomAnimation";
import FuncModules from "../static/js/FuncModules";

const ArtisteConnectBlogs = () => {
  const [getPosts, setPosts] = useState([]);
  //const [getErrors, setErrors] = useState([]);


  useEffect(() => {
    const allPosts = async () => {
      const gatDatalink = await HttpVerbs.fetchBlogs(Endpoints.getallblogs);
      setPosts(gatDatalink);
    }
    allPosts();
  }, []);

  if (getPosts.length < 1) {
    return (
      <div>
        <CustomAnimation>
            <Loading />
        </CustomAnimation>
      </div>
    );
  } else {
    return (
      <section className="blog-section-wrapper">
        <div className="blog-items-container container">

          {getPosts.map((posts) => {
            return (
              <CustomAnimation>
                <div className="blog-items">

                  <div className="blog-item-image-wrapper">
                    <Link  to={Endpoints.artisteadvice + `/${posts.id}/${posts.title}`}>
                      <img src={posts.image} alt="Images" />
                    </Link>
                  </div>
                  <div className="blog-text-content-wrapper">
                    <Link to={Endpoints.artisteadvice + `/${posts.id}/${posts.title}`}>
                      <h1>{posts.title}</h1>
                    </Link>
                    <p>
                      {posts.content}
                    </p>
                    <div className="date-and-readmore-wrapper">
                      <div className="blog-date">{FuncModules.formatDate(posts.date)}</div>
                      <div className="blog-readmore">
                        <Link  to={Endpoints.artisteadvice + `/${posts.id}/${posts.title}`}>Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomAnimation>
            )
          })}
        </div>
      </section>
    );
  }
};

export default ArtisteConnectBlogs;
