/** @format */

import React from "react";
import { Link } from "react-router-dom";
const CustomLink = ({
  textLabel = "",
  linkLabel,
  route,
  className,
  isReload = false,
  onclick = null,
}) => {
  if (onclick !== null) {
    return (
      <div style={{textAlign: 'right', marginRight: '15%'}}>
        <p>
          {textLabel}
          <a className={className} onClick={onclick}>
            {linkLabel}
          </a>
        </p>
      </div>
    );
  }
  if (isReload) {
    return (
      <div>
        <p>
          {textLabel}
          <a className={className} href={route}>
            {linkLabel}
          </a>
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          {textLabel}
          <Link className={className} to={route}>
            {linkLabel}
          </Link>
        </p>
      </div>
    );
  }
};

export default CustomLink;
