
import React from 'react'
import ArtisteConnectBlogs from '../../components/ArtisteConnectBlogs';
import ArtisteConnectJumbo from '../../components/ArtisteConnectJumbo';
import TestimonialSection from '../../components/TestimonialSection';
import FooterSection from '../../components/FooterSection';
import SocialContactSection from '../../components/SocialContactSection';

const Blog = () => {
  return (
    <div className=''>
      <ArtisteConnectJumbo jumboText="Artiste Advice" />
      <ArtisteConnectBlogs />
      <div className='artist-advice-wrap'>
        {/* social contacts */}
        <SocialContactSection />
        <TestimonialSection />
        <FooterSection />
      </div>
    </div>
  )
}

export default Blog;