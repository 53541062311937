/** @format */

import React, { useState } from "react";
import {
  FaEnvelope,
  FaLock,
  FaPhoneAlt,
} from "react-icons/fa";
import CustomFormInput from "../../../components/CustomFormInput";
import CustomButton from "../../../components/CustomButton";
import CustomLink from "../../../components/CustomLink";
import { useNavigate } from "react-router-dom";

const RadioStationForm = () => {
  const navigate = useNavigate();
  /**set the states */
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/user/otpcheck");
    // Perform login logic here
  };

  return (
    <div>
      <form className="form-wrapper" onSubmit={handleSubmit}>
      

        <CustomFormInput
          placeholder="Email Address"
          className="input-field"
          type="email"
          id="email"
          value={email}
          PrefIcon={FaEnvelope}
          onChange={handleEmailChange}
        />

        <CustomFormInput
          placeholder="Phone Number"
          className="input-field"
          type="number"
          id="phoneNumber"
          value={phoneNumber}
          PrefIcon={FaPhoneAlt}
          onChange={handlePhoneNumberChange}
        />

        <CustomFormInput
          placeholder="Enter Password"
          className="input-field"
          type="password"
          id="password"
          value={password}
          PrefIcon={FaLock}
          onChange={handlePasswordChange}
        />

        <CustomFormInput
          placeholder="Confirm Password"
          className="input-field"
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          PrefIcon={FaLock}
          onChange={handleConfirmPasswordChange}
        />

        <CustomButton
          type="submit"
          className="submit-button"
          btnText="Sign up"
        />

        <div className="sign_reg_in_linkWrap">
          <CustomLink
            linkLabel="sign in"
            textLabel="Already have an account ? "
            className="sign_reg_in_link"
            route="/user/login"
          />
        </div>
      </form>
    </div>
  );
};

export default RadioStationForm;
