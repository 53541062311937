import { React } from "react";
import { Outlet, Link } from "react-router-dom";
import CustomAnimation from "../static/js/CustomAnimation";

const SliderSection = () => {
  return (
    <div className="container-wrapper slider-wrapper">
      <div className="image-wrapper container">
        <div className="slider-text-wrapper">
          <h1>
            <CustomAnimation duration={0.1}>Sell your music online globally</CustomAnimation>
          </h1>
          <p>
            <CustomAnimation duration={0.2}>
              Release and distribute your music on over 150 major streaming
              stores across the world like Spotify, Tidal, Apple Music, Deezer,
              TikTok, Amazon Music, BoomPlay, Google Play Music, Global Radio,
              Tencent QQ, Soundcloud Go, and so on.
            </CustomAnimation>
          </p>
        </div>
        <div className="slider-btn-wrapper">
          <div className="slider-btn">
            <CustomAnimation duration={0.3}>
              <a className="slide-btn-first" href="https://distribution.flaretechmusic.com/signup.cfm">
                Start selling now
              </a>
            </CustomAnimation>
          </div>
          <div className="slider-btn">
            <CustomAnimation duration={0.4}>
              <Link className="slide-btn-last" to="#">
              Promote Track on Radio Stations
              </Link>
            </CustomAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderSection;
