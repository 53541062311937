/** @format */

import React from "react";

const CustomButton = ({btnText , type = "submit",className}) => {
  return (
    <div>
      <button  type={type} className={className}>
        {btnText}
      </button>
    </div>
  );
};

export default CustomButton;
