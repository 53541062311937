import React from 'react';

function AppInstallUi() {
    return (
        <div className='install-app-wrapp'>
            <div id="installContainer" className='installAppButton'>
                <p>Do you want to install this app?</p>
                <button id="installButton">Install</button>
            </div>

            {/* <!-- Container for open app prompt --> */}
            <div id="openAppContainer" className='openAppButton'>
                <p>Open in App?</p>
                <button id="openAppButton">Open</button>
            </div>

        </div>
    );
}

export default AppInstallUi;