import React, { useState } from 'react';

function BlogPost() {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState({ text: '', type: '' });  // 'success' or 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('author', author);
    formData.append('content', content);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await fetch('https://lcs.bcods.com/admin/blog/post', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessage({ text: 'Successfully posted!', type: 'success' });
        setTitle('');
        setAuthor('');
        setContent('');
        setImage(null);
      } else {
        const data = await response.json();
        setMessage({ text: data.message || 'Error occurred!', type: 'error' });
      }
    } catch (error) {
      setMessage({ text: 'Network error. Please try again later.', type: 'error' });
    }
  };

  return (
    <div className="admin-section">
      <h2>Manage Blog Post</h2>
      {message.text && (
        <div style={{ color: message.type === 'success' ? 'green' : 'red' }}>
          {message.text}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
        <textarea
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <input
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <button type="submit">Post</button>
      </form>
    </div>
  );
}

export default BlogPost;