import React, { useEffect, useState } from "react";

const FooterSection = () => {

  const [getyear, setYear] = useState("2010");

  function setCurrentYear() {
    const now = new Date();
    const year = now.getFullYear();
    setYear(year);
  }

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    if (getyear !== currentYear) {
      setCurrentYear();
    }
  }, [getyear])

  return (
      <section className="footer-section">
        <div className="footer-section-container container">
          <div className="footer-copyright">
            <p>&copy; {getyear} FlareTechMusic</p>
            <div>
              <div style={{ maxWidth: "600px", margin: "10px auto", }}>
                <a href="#" >Email: info@flaretechmusic.com</a>
              </div>
              <p>
                <a href="https://t.me/+hrC8wL3m-foxOGRk">Telegram</a>
                <a href="https://chat.whatsapp.com/K9H6woL8nOR5s9Czbvv7a4">WhatsApp</a>
                <a href="https://www.youtube.com/channel/UC6I4QamfRRSizf9CFJrOE5A">Youtube</a>
                <a href="https://twitter.com/flaretechmusic?t=g5ilw92I9RaKlWqe8P1Odw&s=09">Twitter</a>
                <a href="https://www.facebook.com/flaretechmusic/">Facebook</a>
                <a href="https://www.instagram.com/p/CVujogQrjuq/?utm_medium=copy_link">Instagram</a>
              </p>
              <hr />
            </div>
            <div className="policies-wrapper">
              <a  href="/">Privacy Policy</a>
              <a href="/">Terms of Use</a>
              <a href="/">Cookie Policy</a>
            </div>
          </div>
        </div>
      </section>
  );
};

export default FooterSection;
