import React from 'react';
import CustomAnimation from '../static/js/CustomAnimation';

function PriceTabeTwo(props) {
    return (
        <div className='pricing-two-table-container'>
            <section className='container pricing-two-table-wrapper'>
                <div className='price-two-list-wrapper'>
                    <div className='price-two-list-wrap'>
                        <CustomAnimation duration={0.2}>
                            <div className='price-two-items'>
                                <div className='price-two-title-and-amount-wrap'>
                                    <span>Forever Standard</span>
                                    <strong>$49.99</strong>
                                </div>
                                <div className='price-two-feature-list-wrap'>
                                    <li>1 Artiste</li>
                                    <li>1 Product</li>
                                    <li>10 Tracks</li>
                                    <li>Forever</li>
                                    <li>150 Outlets</li>
                                    <li>Keep 80% Royalty</li>
                                </div>
                                <a href="#">Subscribe</a>
                            </div>
                        </CustomAnimation>
                        <CustomAnimation duration={0.1}>
                            <div className='price-two-items'>
                                <div className='price-two-title-and-amount-wrap'>
                                    <span className='price-two-unlimited-forever'>Unlimited Forever</span>
                                    <strong>$69.99</strong>
                                </div>
                                <div className='price-two-feature-list-wrap'>
                                    <li>1 Artiste</li>
                                    <li>1 Product</li>
                                    <li>Unlimited Tracks</li>
                                    <li>Forever</li>
                                    <li>150 Outlets</li>
                                    <li>Keep 70% Royalty</li>
                                </div>
                                <a href="#">Subscribe</a>
                            </div>
                        </CustomAnimation>
                        <CustomAnimation duration={0.2}>
                            <div className='price-two-items'>
                                <div className='price-two-title-and-amount-wrap'>
                                    <span className='price-two-easybuy'>Easy Buy</span>
                                    <strong>$1.59</strong>
                                </div>
                                <div className='price-two-feature-list-wrap'>
                                    <li>1 Artiste</li>
                                    <li>1 Product</li>
                                    <li>1 Tracks</li>
                                    <li>1 Month</li>
                                    <li>150 Outlets</li>
                                    <li>Keep 100% Royalty</li>
                                </div>
                                <a href="#">Subscribe</a>
                            </div>
                        </CustomAnimation>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PriceTabeTwo;