import React from "react";
import CustomAnimation from "../static/js/CustomAnimation";

const ArtisteConnectJumbo = ({ jumboText }) => {
  return (
    <section className="artistes-jumbo-wrapper">
      <CustomAnimation>
        <div className="artistes-jumbo-overlaybg">
          <div className="artistes-jumbo-container container">
            <h1>{jumboText}</h1>
          </div>
        </div>
      </CustomAnimation>
    </section>
  );
};

export default ArtisteConnectJumbo;
